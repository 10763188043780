@import "/src/styles/mixin";

.wrap {
  padding: 32px 60px 24px;
  width: 100%;
  border-radius: var(--border-radius-medium);
  background-repeat: no-repeat, no-repeat;
  background-image: url("../../../assets/images/sale-hits-label.svg"),
    url("../../../assets/images/hits-background.webp");
  background-position: 25% 3%, center center;
  background-size: 23%, cover;

  @media screen and (max-width: 1140px) {
    padding: 32px 56px 24px;
    background-size: 33%, cover;
  }

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    padding: 16px 12px 16px;
    background-size: 45%, cover;
  }
  @media screen and (max-width: 425px) {
    background-position: 36% 3%, center center;
  }

  .top {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 7px;

    @media screen and (max-width: 500px) {
      flex-direction: row;
      justify-content: space-between;
    }

    @media screen and (max-width: 370px) {
      flex-direction: column;
    }

    .timer {
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: space-between;

      @media screen and (max-width: 500px) {
        width: fit-content;
      }

      &Text {
        @include font18;
        font-weight: 500;
        @media screen and (max-width: 500px) {
          display: none;
        }
      }
    }
  }
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 32px;

  @media screen and (max-width: 768px) {
    gap: 8px;
  }

  @media screen and (max-width: 375px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.title {
  @include font24;
}