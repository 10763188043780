@import "/src/styles/mixin";

.timer {
  &Items {
    @include font20;
    display: flex;
    gap: 24px;

    @media screen and (max-width: 1024px) {
      @include font18;
      font-weight: 800;
      gap: 12px;
    }

    @media screen and (max-width: 767px) {
      @include font16;
      font-weight: 800;
    }
  }

  &Item {
    min-width: 50px;
    padding: 8px 12px;
    position: relative;
    background-color: var(--white-color);
    border: 1px solid var(--grey-lines-color);
    border-radius: var(--border-radius-small);
    text-align: center;

    &:not(:last-child)::after {
      content: ":";
      position: absolute;
      right: -14px;
      @media screen and (max-width: 1024px) {
        right: -8.5px;
      }
    }

    @media screen and (max-width: 1024px) {
      padding: 8px;
      min-width: 44px;
    }

    @media screen and (max-width: 767px) {
      padding: 2px;
      min-width: 34px;
    }
  }
}