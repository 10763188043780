@import "/src/styles/mixin";

.swiper-hits {
  .swiper {
    &-wrapper {
      padding-bottom: 32px;
    }

    &-button {
      @include arrowSwiper;
    }

    &-pagination {
      @include swiperPagination;
      display: block;
      margin-top: 24px;
      bottom: -6px !important;
      z-index: 1;

      &-bullet {
        width: 10px !important;
        height: 10px !important;
      }

      &-bullet-active {
        background: var(--primary-color) !important;
      }
    }

    @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
      padding-bottom: 24px;
    }
  }
}